const colors = {
  // link colors
  link: {
    dark: 'text-gray-400 data-[hovered=true]:text-gray-800 data-[active=true]:text-green-500',
    light: 'text-white data-[hovered=true]:text-green-300',
    lightPrimary: 'text-gray-400 data-[hovered=true]:text-green-500',
    primary: 'text-green-500 data-[hovered=true]:text-green-600',
    secondary: 'text-green-900 data-[hovered=true]:text-green-950'
  },
  // solid colors
  solid: {
    dark: 'border-gray-800 data-[hovered=true]:border-gray-900 bg-gray-800 data-[hovered=true]:bg-gray-900 text-white',
    light:
      'border-white data-[hovered=true]:border-gray-100 bg-white data-[hovered=true]:bg-gray-100 text-black',
    lightPrimary:
      'border-white bg-white data-[hovered=true]:border-green-500 data-[hovered=true]:bg-green-500 text-gray-400 data-[hovered=true]:text-white',
    primary:
      'border-green-500 data-[hovered=true]:border-green-600 bg-green-500 data-[hovered=true]:bg-green-600 text-white',
    secondary:
      'border-green-900 data-[hovered=true]:border-green-950 bg-green-900 data-[hovered=true]:bg-green-950 text-white'
  },
  // outline colors
  outline: {
    dark: 'border-gray-800 data-[hovered=true]:bg-gray-800 text-gray-800 data-[hovered=true]:text-white',
    light:
      'border-white data-[hovered=true]:bg-white text-white data-[hovered=true]:text-gray-800',
    lightPrimary:
      'border-gray-100 bg-white text-gray-400 data-[hovered=true]:text-white data-[hovered=true]:bg-green-500 data-[hovered=true]:border-green-500 data-[active=true]:text-white data-[active=true]:bg-green-500 data-[active=true]:border-green-500',
    grayPrimary:
      'border-gray-100 bg-white text-gray-400 data-[hovered=true]:text-green-500 data-[hovered=true]:border-green-500',
    primary:
      'border-green-500 data-[hovered=true]:bg-green-500 text-green-500 data-[hovered=true]:text-white',
    secondary:
      'border-green-900 data-[hovered=true]:bg-green-900 text-green-900 data-[hovered=true]:text-white'
  },
  // ghost colors
  ghost: {
    dark: 'data-[hovered=true]:border-gray-50 data-[hovered=true]:bg-gray-50 text-gray-800',
    light:
      'data-[hovered=true]:border-gray-800/5 data-[hovered=true]:bg-gray-800/5 text-white data-[hovered=true]:text-gray-800',
    lightPrimary:
      'data-[hovered=true]:border-green-500/5 data-[hovered=true]:bg-green-500/5 text-gray-400 data-[hovered=true]:text-green-500',
    primary:
      'data-[hovered=true]:border-green-500/5 data-[hovered=true]:bg-green-500/5 text-green-500',
    secondary:
      'data-[hovered=true]:border-green-900/5 data-[hovered=true]:bg-green-900/5 text-green-900'
  }
}

export default colors
