'use client'

import { ForwardedRef, forwardRef } from 'react'
import { NextLink } from '@/utils/navigation'
import { Vector } from '@/components/ui/vector'
import { Link as LinkAria } from 'react-aria-components'
import { useLink, useLinkProps } from './use-link'

export type LinkProps = useLinkProps

const Link = forwardRef(
  (props: LinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    const {
      linkRef,
      styles,
      className,
      activeClassName,
      children,
      startIcon,
      startIconClassName,
      endIcon,
      endIconClassName,
      componentProps,
      isDisabled,
      isActive
    } = useLink({
      ...props,
      ref
    })

    return (
      <LinkAria isDisabled={isDisabled}>
        {/* @ts-ignore */}
        <NextLink
          {...componentProps}
          ref={linkRef}
          className={styles.base({
            className: `${className} ${isActive ? activeClassName : ``}`
          })}
          data-active={isActive}
        >
          {!!startIcon && (
            <Vector
              className={styles.icon({ className: startIconClassName })}
              name={typeof startIcon === 'string' ? startIcon : startIcon.name}
            />
          )}
          {children}
          {!!endIcon && (
            <Vector
              className={styles.icon({ className: endIconClassName })}
              name={typeof endIcon === 'string' ? endIcon : endIcon.name}
            />
          )}
        </NextLink>
      </LinkAria>
    )
  }
)

Link.displayName = 'Link'

export default Link
