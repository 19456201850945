import { tv, VariantProps } from '@/utils/tv'

export const spinner = tv({
  slots: {
    base: ['animate-spin']
  },
  variants: {
    size: {
      sm: {
        base: 'w-5 h-5'
      },
      md: {
        base: 'w-8 h-8'
      },
      lg: {
        base: 'w-10 h-10'
      }
    },
    color: {
      dark: {
        base: 'text-gray-800/40'
      },
      light: {
        base: 'text-white'
      },
      primary: {
        base: 'text-green-500'
      },
      secondary: {
        base: 'text-green-800'
      }
    }
  },
  defaultVariants: {
    size: 'sm'
  }
})

export type SpinnerVariantProps = VariantProps<typeof spinner>
