import { ComponentProps, Ref, useMemo } from 'react'
import NextLinkProps from 'next/link'
import { usePathname } from '@/utils/navigation'
import { AriaButtonProps } from '@react-aria/button'
import { SvgDetails } from '@/types/icon'
import { ButtonVariantProps, button } from '@/theme/components/button'

export type useLinkProps = Omit<
  ComponentProps<typeof NextLinkProps>,
  'locale'
> &
  Omit<AriaButtonProps, 'className' | 'href'> &
  ButtonVariantProps & {
    ref?: Ref<HTMLAnchorElement>
    // href: string
    className?: string
    activeClassName?: string
    title?: string
    disabled?: boolean
    startIcon?: SvgDetails
    startIconClassName?: string
    endIcon?: SvgDetails
    endIconClassName?: string
    isActive?: boolean
  }

export const useLink = (props: useLinkProps) => {
  const {
    ref: linkRef,
    className = '',
    activeClassName = '',
    children,
    href,
    startIcon,
    startIconClassName = '',
    endIcon,
    endIconClassName = '',
    font = 'rubik',
    variant = 'link',
    size = 'md',
    color = 'dark',
    weight = 'medium',
    disabled = false,
    prefetch = true,
    rounded = false,
    fullWidth = false,
    isBetween = false,
    isIconOnly: isIconOnlyProp = false,
    isActive = false,
    ...otherProps
  } = props

  const isIconOnly = (!children && (!!startIcon || !!endIcon)) || isIconOnlyProp

  const pathname = usePathname()

  const styles = useMemo(() => {
    return button({
      font,
      variant,
      size,
      color,
      weight,
      rounded,
      fullWidth,
      isIconOnly,
      isBetween
    })
  }, [
    font,
    variant,
    size,
    color,
    weight,
    rounded,
    fullWidth,
    isIconOnly,
    isBetween
  ])

  return {
    linkRef,
    className,
    activeClassName,
    children,
    styles,
    startIcon,
    startIconClassName,
    endIcon,
    endIconClassName,
    isActive: pathname === href || isActive,
    isDisabled: disabled,
    componentProps: {
      href,
      prefetch,
      ...otherProps
    }
  }
}
